import React, { Suspense } from "react";
import styles from "./NavBar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSun,
  faMoon,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../context/DataContext";
import { Squircle } from "@squircle-js/react";
import ToggleDropdown1 from "./ToggleDropdown1.js";

const NavBar = () => {
  // Check localStorage for saved theme preference on initial load
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return localStorage.getItem("theme") === "dark";
  });

  // Function to toggle theme
  const toggleTheme = () => {
    const newTheme = !isDarkMode ? "dark" : "light";
    setIsDarkMode(!isDarkMode);
    document.documentElement.setAttribute("data-theme", newTheme);
    localStorage.setItem("theme", newTheme); // Save theme preference in localStorage
  };

  // Effect to apply theme on component mount
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "light";
    document.documentElement.setAttribute("data-theme", savedTheme);
    setIsDarkMode(savedTheme === "dark");
  }, []);

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarContainer}>
        {/* Logo */}
        <Link to="/" className={styles.navbarLogoContainer}>
          <h1 className={styles.navbarLogo}>
            GCSE<span className={styles.navbarLogoSubscript}>SIMULATOR</span>
          </h1>
        </Link>

        <div className={styles.themeToggle} onClick={toggleTheme}>
          {isDarkMode ? (
            <FontAwesomeIcon icon={faSun} className={styles.themeIcon} />
          ) : (
            <FontAwesomeIcon icon={faMoon} className={styles.themeIcon} />
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
