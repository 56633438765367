import logo from "./logo.svg";
import "./App.css";
import { useState, useEffect } from "react";
import Home from "./pages/Home";
import NavBar from "./Components/NavBar";
import { Routes, Route } from "react-router-dom";
import Privacy from "./pages/Privacy"; // Import your Learning Map component

function App() {
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </div>
  );
}

export default App;
