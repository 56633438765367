import React, { act, useRef } from "react";
import { useEffect, useState, useMemo, useContext } from "react";
import styles from "../styles/Home.module.css";
import NavBar from "../Components/NavBar";
import "../App.css";
import { Squircle } from "@squircle-js/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToggleDropdown1 from "../Components/ToggleDropdown1";
import CheckBox1 from "../Components/CheckBox1";
import { Link } from "react-router-dom";

import {
  faInfo,
  faPause,
  faPlay,
  faRedo,
  faExpand,
  faX,
  faCog,
  faTableCells,
  faVolumeHigh,
  faChevronRight,
  faSpinner,
  faTable,
  faList,
  faFilter,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { DataContext } from "../context/DataContext";

export default function Home() {
  return (
    <div className={`pageContainer`}>
      <div className={styles.homePageContainer}>
        <div className={styles.heroContainer}>
          <h1 className={styles.heroTitle}>revision done right.</h1>
          <h1 className={styles.heroSubTitle}>
            GCSE Simulator is a resource that is designed to be the easiest,
            most convenient way to access all resources and practice for the
            standardised UK exams, the GCSE.
          </h1>
        </div>
        <div className={styles.footerContainer}>
          <h2>
            <span className={styles.footerLabel}>Contact:</span>
            gcsesimulator@gmail.com
          </h2>
          <h2>
            <Link to="/privacy" className={styles.footerLabel}>
              Privacy
            </Link>
          </h2>
        </div>
      </div>
    </div>
  );
}
