import React, { useState, useEffect } from "react";
import { DataContext } from "./DataContext";

export const DataContextProvider = ({ children }) => {
  const [test, setTest] = useState(false);

  return (
    <DataContext.Provider
      value={{
        test,
        setTest,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
