import styles from "../styles/Privacy.module.css";

export default function Privacy() {
  return (
    <div className={styles.pageContainer}>
      <div className={styles.privacyContainer}>
        <h1 className={styles.title}>Privacy Policy</h1>
        <p className={styles.text}>
          At GCSE Simulator, we are committed to protecting your privacy. This
          policy explains how we handle your information and ensure your
          personal data remains private.
        </p>
        <h2 className={styles.subtitle}>What Information We Collect</h2>
        <p className={styles.text}>
          We do not collect any personal data. All interactions with the app
          are local and do not involve external data storage or processing.
        </p>

        <p className={styles.text}>
          All data stored are local on device such as your Guest ID, theme preferences and subjects.
        </p>
        <h2 className={styles.subtitle}>How We Use Your Information</h2>
        <p className={styles.text}>
          As we do not collect personal information, no data is processed,
          stored, or shared externally outside the device you interact with.
        </p>
        <h2 className={styles.subtitle}>Changes to This Policy</h2>
        <p className={styles.text}>
          We may update this Privacy Policy to reflect changes in the app or
          regulations. Please check this page periodically for updates.
        </p>
        <h2 className={styles.subtitle}>Contact Us</h2>
        <p className={styles.text}>
          If you have any questions about this Privacy Policy, please contact us
          at <a href="mailto:gcsesimulator@gmail.com" className={styles.link}>gcsesimulator@gmail.com</a>.
        </p>
      </div>
    </div>
  );
}
